html {
  background-color: #b8b89e;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  min-height: 100vh;
  background-color: #b8b89e;
}

.SpinLogo {
  -webkit-animation: swing-opposite infinite 6s linear;
          animation: swing-opposite infinite 6s linear;
  /* height: 40vmin; */
  pointer-events: none;
}
.SpinLogoSlow {
  -webkit-animation: swing-opposite infinite 300s linear;
          animation: swing-opposite infinite 300s linear;
  /* height: 40vmin; */
  pointer-events: none;
}

.Shake {
  -webkit-animation: shake infinite 0.01s linear;
          animation: shake infinite 0.01s linear;
}

.Pan {
  -webkit-animation: swing infinite 1s ease-in-out;
          animation: swing infinite 1s ease-in-out;
}

.Wiggle {
  -webkit-animation: shake infinite 1s linear;
          animation: shake infinite 1s linear;
}

.Rank {
  -webkit-animation: bounce infinite 1s ease-in-out;
          animation: bounce infinite 1s ease-in-out;
}
.Spin:hover {
  -webkit-animation: spin infinite 1s linear;
          animation: spin infinite 1s linear;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes swing {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  25% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  25% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes shake {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg);
  }
  50% {
    -webkit-transform: rotate(-3deg);
            transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@-webkit-keyframes swing-opposite {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@keyframes swing-opposite {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}


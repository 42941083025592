.App {
  text-align: center;
  min-height: 100vh;
  background-color: #b8b89e;
}

.SpinLogo {
  animation: swing-opposite infinite 6s linear;
  /* height: 40vmin; */
  pointer-events: none;
}
.SpinLogoSlow {
  animation: swing-opposite infinite 300s linear;
  /* height: 40vmin; */
  pointer-events: none;
}

.Shake {
  animation: shake infinite 0.01s linear;
}

.Pan {
  animation: swing infinite 1s ease-in-out;
}

.Wiggle {
  animation: shake infinite 1s linear;
}

.Rank {
  animation: bounce infinite 1s ease-in-out;
}
.Spin:hover {
  animation: spin infinite 1s linear;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
  25% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes swing-opposite {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
